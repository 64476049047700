import React from 'react';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';

// shows the info about a reward tier
const RewardCard = ({ tier, selected, handleSelect }) => (
  <Card style={{ height: '100%' }}>
    <CardContent>
      <Typography variant="h5">${tier.cost}</Typography>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {tier.title}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {tier.description}
      </Typography>
      <Typography variant="caption">Comes With</Typography>
      <ul>
        {tier.rewards.map((reward) => (
          <li style={{ listStyleType: 'none' }}>
            <Typography variant="body2">
              -&emsp;
              {reward}
            </Typography>
          </li>
        ))}
      </ul>
    </CardContent>
    {selected === true ? (
      <CardActions>
        {/* <form action="http://localhost:4242/create-checkout-session" method="POST">
          <input style={{ display: 'none' }} name="tierName" value={tier.title} />
          <input style={{ display: 'none' }} name="tierPrice" value={tier.cost} /> */}
        <Button onClick={() => handleSelect(tier)}>Select</Button>
        {/* </form> */}
      </CardActions>
    ) : (
      <></>
    )}
  </Card>
);

export default RewardCard;
