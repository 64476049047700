import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Card,
  Paper,
  CardContent
} from '@mui/material';
import { getDatabase, ref, set, push, get } from 'firebase/database';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import Page from './Page';
import { UserContext } from '../context/UserContext';
import RewardCard from './RewardCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const EditProject = () => {
  const [value, setValue] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [raiseAmount, setRaiseAmount] = useState('');
  const [amountRaised, setAmountRaised] = useState('');
  const [addingTier, setAddingTier] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [tierTitle, setTierTitle] = useState('');
  const [tierDescription, setTierDescription] = useState('');
  const [rewards, setRewards] = useState([]);
  const [newReward, setNewReward] = useState('');
  const [cost, setCost] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const context = useContext(UserContext);
  const { project } = useParams();
  const navigate = useNavigate();
  const db = getDatabase();

  // gets all the current project information
  useEffect(() => {
    const projectRef = ref(db, `projects/${project}`);
    get(projectRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setProjectName(data.name);
          setProjectDescription(data.description);
          setRaiseAmount(data.amount);
          setTiers(data.tiers);
          setAmountRaised(data.raised);
          setThumbnail(data.thumbnail);
          console.log(snapshot.val());
        } else {
          console.log('nothing');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // updates the entire project's data
  const updateProjectData = () => {
    const projectRef = ref(db, `projects/${project}`);
    set(projectRef, {
      name: projectName,
      description: projectDescription,
      amount: raiseAmount,
      raised: amountRaised,
      tiers,
      user: context.auth.currentUser.uid,
      thumbnail
    });
  };

  // for changing tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addTier = () => {
    const tier = {
      title: tierTitle,
      description: tierDescription,
      rewards,
      cost
    };
    setTiers([...tiers, tier]);
  };

  // clears all the reward data so its not there when a new tier is made
  const clearForm = () => {
    setAddingTier(false);
    setTierTitle('');
    setTierDescription('');
    setRewards([]);
    setNewReward('');
    setCost('');
  };

  return (
    <Page title="Edit Project">
      <Container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab label="Basics" {...a11yProps(0)} />
              <Tab label="Rewards" {...a11yProps(1)} />
              {/* <Tab label="Payments" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack>
              <Typography variant="h6">Project Name</Typography>
              <TextField
                id="name"
                variant="outlined"
                size="small"
                margin="normal"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
              />
              <Typography variant="h6">Project Description</Typography>
              <TextField
                id="description"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                value={projectDescription}
                onChange={(e) => {
                  setProjectDescription(e.target.value);
                }}
              />
              <Typography variant="h6">Amount To Raise</Typography>
              <TextField
                id="description"
                variant="outlined"
                size="small"
                margin="normal"
                type="number"
                value={raiseAmount}
                onChange={(e) => {
                  setRaiseAmount(e.target.value);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {addingTier ? (
              <Stack>
                <Typography variant="h6">Backer Level Title</Typography>
                <TextField
                  id="level-name"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={tierTitle}
                  onChange={(e) => {
                    setTierTitle(e.target.value);
                  }}
                />
                <Typography variant="h6">Tier Description</Typography>
                <TextField
                  id="description"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={tierDescription}
                  onChange={(e) => {
                    setTierDescription(e.target.value);
                  }}
                />
                <Typography variant="h6">List of Rewards</Typography>
                <Grid container columnSpacing={5} rowSpacing={1} sx={{ mt: 1, mb: 1 }}>
                  {rewards.map((reward) => (
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography>{reward}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item md={10} sm={12}>
                    <TextField
                      id="level-name"
                      variant="outlined"
                      size="small"
                      label="Add Another"
                      value={newReward}
                      onChange={(e) => [setNewReward(e.target.value)]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2} sm={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      margin="normal"
                      onClick={() => {
                        setRewards([...rewards, newReward]);
                        setNewReward('');
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant="h6">Tier Price</Typography>
                <TextField
                  id="description"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  type="number"
                  value={cost}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
                <Grid container columnSpacing={2} sx={{ mt: 5 }}>
                  <Grid item sm={12} md={4}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        addTier();
                        clearForm();
                      }}
                    >
                      Add Tier
                    </Button>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      color="warning"
                      variant="contianed"
                      fullWidth
                      onClick={() => {
                        clearForm();
                      }}
                    >
                      Don't Add Tier
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Grid container spacing={5}>
                <Grid item md={3} sm={12}>
                  <Card
                    variant="outlined"
                    style={{ border: '1px dashed rgba(145, 158, 171, 0.5)', height: '100%' }}
                    onClick={() => {
                      setAddingTier(true);
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Stack>
                        <AddIcon color="action" sx={{ fontSize: 150 }} />
                        <Typography variant="h6" color="action">
                          Add a Reward Tier
                        </Typography>
                      </Stack>
                    </Box>
                  </Card>
                </Grid>
                {tiers.map((tier) => (
                  <Grid item md={3} sm={12}>
                    <RewardCard tier={tier} />
                  </Grid>
                ))}
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <Grid container columnSpacing={3}>
            <Grid item md={4} sm={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() => {
                  updateProjectData();
                  navigate('/dashboard/projects', { replace: true });
                }}
              >
                Update Project
              </Button>
            </Grid>
            <Grid item md={4} sm={12}>
              <Button
                color="error"
                variant="contained"
                fullWidth
                onClick={() => {
                  navigate('/dashboard/projects', { replace: true });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default EditProject;
