import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Card,
  Paper,
  CardContent,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { getDatabase, ref, set, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Page from './Page';
import { UserContext } from '../context/UserContext';
import RewardCard from './RewardCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ProjectCreation = () => {
  const [value, setValue] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [raiseAmount, setRaiseAmount] = useState('');
  const [addingTier, setAddingTier] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [tierTitle, setTierTitle] = useState('');
  const [tierDescription, setTierDescription] = useState('');
  const [rewards, setRewards] = useState([]);
  const [newReward, setNewReward] = useState('');
  const [cost, setCost] = useState('');
  const [file, setFile] = useState(null);
  const [fundingLength, setFundingLength] = useState(30);
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const storage = getStorage();
  const imageRef = storageRef(storage, 'images');

  const writeProjectData = () => {
    const db = getDatabase();
    const projectList = ref(db, 'projects');
    const newProjectRef = push(projectList);
    const projectFolder = storageRef(imageRef, newProjectRef._path.pieces_[1]);
    console.log(fundingLength);
    const finalDate = new Date(Date.now().valueOf() + fundingLength * 24 * 60 * 60 * 1000);
    console.log(finalDate);
    /* eslint-disable */
    set(newProjectRef, {
      name: projectName,
      description: projectDescription,
      amount: raiseAmount,
      tiers,
      raised: '0',
      user: context.auth.currentUser.uid,
      thumbnail: file.name,
      startDate: Date.now(),
      endDate: finalDate,
      done: false
    });
    /* eslint-enable */
    // /* eslint-disable */
    // let formData = new FormData();
    // formData.append('name', newProjectRef._path.pieces_[1]);
    // /* eslint-enable */

    // fetch(`${process.env.REACT_APP_API_URL}/api/createwallet`, {
    //   method: 'POST',
    //   body: formData
    // })
    //   .then((res) => res.json())
    //   .then((json) => {
    //     const db = getDatabase();
    //     const walletRef = ref(db, 'wallets');
    //     const newWalletRef = push(walletRef);
    //     const accountName = json.name;
    //     set(newWalletRef, {
    //       id: [accountName],
    //       address: json.result
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const projectImageRef = storageRef(projectFolder, file.name);
    uploadBytes(projectImageRef, file).then((snapshot) => {
      console.log('uploaded');
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addTier = () => {
    const tier = {
      title: tierTitle,
      description: tierDescription,
      rewards,
      cost
    };
    setTiers([...tiers, tier]);
  };

  const clearForm = () => {
    setAddingTier(false);
    setTierTitle('');
    setTierDescription('');
    setRewards([]);
    setNewReward('');
    setCost('');
  };
  return (
    <Page title="Edit Project">
      <Container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab label="Basics" {...a11yProps(0)} />
              <Tab label="Rewards" {...a11yProps(1)} />
              {/* <Tab label="Payments" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack>
              <Typography variant="h6">Project Name</Typography>
              <TextField
                id="name"
                variant="outlined"
                size="small"
                margin="normal"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
              />
              {/* eslint-disable */}
              <Typography variant="h6">Photo</Typography>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setFile(e.target.files[0]);
                  }}
                />
                <Button variant="contained" component="span">
                  Upload
                </Button>
              </label>
              {file !== null && <Typography variant="body">{file.name}</Typography>}
              {/* eslint-enable */}
              <Typography variant="h6">Project Description</Typography>
              <TextField
                id="description"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                value={projectDescription}
                onChange={(e) => {
                  setProjectDescription(e.target.value);
                }}
              />
              <Typography variant="h6">Amount To Raise</Typography>
              <TextField
                id="description"
                variant="outlined"
                size="small"
                margin="normal"
                type="number"
                value={raiseAmount}
                onChange={(e) => {
                  setRaiseAmount(e.target.value);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
              <Typography variant="h6">Length of Funding</Typography>
              <FormControl margin="normal">
                <InputLabel id="fundinglength">Days</InputLabel>
                <Select
                  labelId="fundingLength"
                  id="fundingLengthSelect"
                  value={fundingLength}
                  label="Days"
                  onChange={(e) => setFundingLength(e.target.value)}
                >
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {addingTier ? (
              <Stack>
                <Typography variant="h6">Backer Level Title</Typography>
                <TextField
                  id="level-name"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={tierTitle}
                  onChange={(e) => {
                    setTierTitle(e.target.value);
                  }}
                />
                <Typography variant="h6">Tier Description</Typography>
                <TextField
                  id="description"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={tierDescription}
                  onChange={(e) => {
                    setTierDescription(e.target.value);
                  }}
                />
                <Typography variant="h6">List of Rewards</Typography>
                <Grid container columnSpacing={5} rowSpacing={1} sx={{ mt: 1, mb: 1 }}>
                  {rewards.map((reward) => (
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography>{reward}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item md={10} sm={12}>
                    <TextField
                      id="level-name"
                      variant="outlined"
                      size="small"
                      label="Add Another"
                      value={newReward}
                      onChange={(e) => [setNewReward(e.target.value)]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={2} sm={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      margin="normal"
                      onClick={() => {
                        setRewards([...rewards, newReward]);
                        setNewReward('');
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant="h6">Tier Price</Typography>
                <TextField
                  id="description"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  type="number"
                  value={cost}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
                <Grid container columnSpacing={2} sx={{ mt: 5 }}>
                  <Grid item sm={12} md={4}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        addTier();
                        clearForm();
                      }}
                    >
                      Add Tier
                    </Button>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      variant="contained"
                      color="warning"
                      fullWidth
                      onClick={() => {
                        clearForm();
                      }}
                    >
                      Don't Add Tier
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Grid container spacing={5}>
                <Grid item md={3} sm={12}>
                  <Card
                    variant="outlined"
                    style={{ border: '1px dashed rgba(145, 158, 171, 0.5)', height: '100%' }}
                    onClick={() => {
                      setAddingTier(true);
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Stack>
                        <AddIcon color="action" sx={{ fontSize: 150 }} />
                        <Typography variant="h6" color="action">
                          Add a Reward Tier
                        </Typography>
                      </Stack>
                    </Box>
                  </Card>
                </Grid>
                {tiers.map((tier) => (
                  <Grid item md={3} sm={12}>
                    <RewardCard tier={tier} />
                  </Grid>
                ))}
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <Grid container columnSpacing={3}>
            <Grid item md={4} sm={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() => {
                  writeProjectData();
                  navigate('/dashboard/projects', { replace: true });
                }}
              >
                Post Project
              </Button>
            </Grid>
            <Grid item md={4} sm={12}>
              <Button
                color="error"
                variant="contained"
                fullWidth
                onClick={() => {
                  navigate('/dashboard/projects', { replace: true });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ProjectCreation;
