import React, { useState, useEffect, useContext, useRef } from 'react';
import { Grid, Container, Typography, Stack, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue, query, equalTo, orderByChild } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import MyProjectCard from './_dashboard/projects/MyProjectCard';
import Page from './Page';
import { UserContext } from '../context/UserContext';

// ----------------------------------------------------------------------

const MyProjects = ({ ...other }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState({});
  const proRef = useRef({});
  const context = useContext(UserContext);
  const db = getDatabase();
  const storage = getStorage();

  useEffect(() => {
    const projectsRef = query(
      ref(db, 'projects'),
      orderByChild('user'),
      equalTo(context.auth.currentUser !== null ? context.auth.currentUser.uid : '')
    );
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      const newProjects = [];
      /* eslint-disable */
      for (const i in data) {
        if (!proRef.current.hasOwnProperty(i)) {
          newProjects.push(i);
        }
      }
      if (newProjects.length !== 0) {
        proRef.current = {};
        for (const i in data) {
          if (!projects.hasOwnProperty(i)) {
            getDownloadURL(storageRef(storage, `images/${i}/${data[i].thumbnail}`))
              .then((url) => {
                proRef.current = {
                  ...proRef.current,
                  [i]: { ...data[i], cover: url }
                };
                setProjects({ ...proRef.current });
              })
              .catch((error) => {
                proRef.current = {
                  ...proRef.current,
                  [i]: { ...data[i], cover: '/static/mock-images/pexels-xxss-is-back-777001.jpg' }
                };
                setProjects({ ...proRef.current });
              });
          }
        }
      }

      /* eslint-enable */
    });
  }, [context]);

  return (
    <Page title="My Projects">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          My Projects
        </Typography>
        {/* <img id="image" alt="test" /> */}
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate('/dashboard/create-project');
              }}
            >
              Start Project
            </Button>
          </Stack>
        </Stack>
        <Grid container spacing={3} {...other}>
          {Object.entries(proRef.current).map((project) => {
            console.log(project[0]);
            return (
              <Grid key={project[0]} item xs={12} sm={6} md={3}>
                <MyProjectCard product={project[1]} projectId={project[0]} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
};

export default MyProjects;
