import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, get, ref, query, orderByChild, equalTo } from 'firebase/database';
import { Typography } from '@mui/material';

const Profile = () => {
  const [balance, setBalance] = useState(0);
  const db = getDatabase();
  const auth = getAuth();

  useEffect(() => {
    const walletRef = query(
      ref(db, 'wallets'),
      orderByChild('id'),
      equalTo(auth.currentUser !== null ? auth.currentUser.uid : '')
    );
    get(walletRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const key = Object.keys(data)[0];
        /* eslint-disable */
        let formData = new FormData();
        formData.append('wallet', data[key].id);
        /* eslint-enable */

        fetch(`${process.env.REACT_APP_API_URL}/api/getbalance`, {
          method: 'POST',
          body: formData
        })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);
            setBalance(json.result);
            if (!json.error) {
              console.log('oh no');
            }
          });
      }
    });
  }, []);

  return (
    <div>
      <Typography>Account Balance: $ {balance}</Typography>
    </div>
  );
};

export default Profile;
