import React, { useState, useEffect, useRef } from 'react';
import { Typography, Stack, Button, Grid, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { limitToFirst, query, ref, onValue, getDatabase } from '@firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import Page from './Page';
import BrowseProjectCard from './_dashboard/projects/BrowseProjectCard';

const BrowseProjects = ({ ...other }) => {
  const [projects, setProjects] = useState({});
  const proRef = useRef({});
  const db = getDatabase();
  const storage = getStorage();

  // get all the projects from the db
  useEffect(() => {
    const projectsRef = query(ref(db, 'projects'), limitToFirst(100));
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      const newProjects = [];
      /* eslint-disable */
      for (const i in data) {
        if (!proRef.current.hasOwnProperty(i)) {
          newProjects.push(i);
        }
      }
      if (newProjects.length !== 0) {
        console.log(data);
        console.log(projectsRef);
        for (const i in data) {
          if (!projects.hasOwnProperty(i)) {
            console.log('hi');
            getDownloadURL(storageRef(storage, `images/${i}/${data[i].thumbnail}`))
              .then((url) => {
                console.log(i, url);
                proRef.current = {
                  ...proRef.current,
                  [i]: { ...data[i], cover: url }
                };
                setProjects({ ...proRef.current });
              })
              .catch((error) => {
                proRef.current = {
                  ...proRef.current,
                  [i]: { ...data[i], cover: '/static/mock-images/pexels-xxss-is-back-777001.jpg' }
                };
                setProjects({ ...proRef.current });
              });
          }
        }
      }
      /* eslint-enable */
      if (
        !(
          newProjects &&
          Object.keys(newProjects).length === 0 &&
          Object.getPrototypeOf(newProjects) === Object.prototype
        )
      ) {
        setProjects({ ...newProjects });
      }
    });
  }, []);

  return (
    <Page title="Projects">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Projects
        </Typography>
        <Grid container spacing={3} {...other}>
          {Object.entries(projects).map((project) => {
            console.log(project[0]);
            return (
              <Grid key={project[0]} item xs={12} sm={6} md={3}>
                <BrowseProjectCard product={project[1]} projectId={project[0]} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
};

export default BrowseProjects;
