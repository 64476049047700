import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Page from './Page';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const ForgotPassword = () => {
  const auth = getAuth();
  const [emailSent, setEmailSent] = useState(false);

  const ResetSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ResetSchema,
    onSubmit: ({ email }, { setSubmitting }) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSubmitting(false);
          setEmailSent(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <RootStyle title="Forgot Password">
      <Container maxwidth="sm">
        <ContentStyle>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3} sx={{ my: 2 }}>
                <Typography variant="h4">Reset Your Password</Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email address"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Send Reset Email
              </LoadingButton>
              {emailSent && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Email sent!
                </Typography>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <Link component={RouterLink} variant="subtitle2" to="/login">
                  Return to login
                </Link>
              </Stack>
            </Form>
          </FormikProvider>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default ForgotPassword;
