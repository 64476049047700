import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, set, query, orderByChild, equalTo } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Container, Typography, Grid, Stack, Card, CardContent } from '@mui/material';
import Page from './Page';
import RewardCard from './RewardCard';
import { UserContext } from '../context/UserContext';

const Invest = () => {
  const { project } = useParams();
  const db = getDatabase();
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [raiseAmount, setRaiseAmount] = useState('');
  const [tiers, setTiers] = useState([]);
  const [amountRaised, setAmountRaised] = useState('');
  const [selected, setSelected] = useState(null);
  const [user, setUser] = useState('');
  const [paying, setPaying] = useState(false);
  const context = useContext(UserContext);
  const auth = getAuth();
  const navigate = useNavigate();

  // gets all of the project info. All is saved because once payments are introduced, the db entry will
  // need to be updated and this is done as to not overwrite or destory any of the other elements
  useEffect(() => {
    const projectRef = ref(db, `projects/${project}`);
    get(projectRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setProjectName(data.name);
          setProjectDescription(data.description);
          setRaiseAmount(data.amount);
          setTiers(data.tiers);
          setAmountRaised(data.raised);
          setUser(data.user);
          console.log(snapshot.val());
        } else {
          console.log('nothing');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // eslint requirement that static elements can have key interactions
  const handleKeyUp = (e, tier) => {
    if (e.key === 'Enter') {
      handleTierSelect(tier);
    }
  };

  // Sets which tier has been selected to show the "select" button on the correct card
  const handleTierSelect = (tier) => {
    setSelected(tier.title);
  };

  const handleSelect = (tier) => {
    context.setTier(tier);
    navigate('/dashboard/pay', { replace: true });
    // const walletRef = query(ref(db, 'wallets'), orderByChild('id'), equalTo(project));
    // get(walletRef).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     const data = snapshot.val();
    //     /* eslint-disable */
    //     let formData = new FormData();
    //     formData.append('from', auth.currentUser.uid);
    //     formData.append('to', data.address);
    //     formData.append('amount', tier.cost);
    //     /* eslint-enable */

    //     fetch(`${process.env.REACT_APP_API_URL}/api/sendtoaddress`, {
    //       method: 'POST',
    //       body: formData
    //     })
    //       .then((res) => res.json())
    //       .then((json) => {
    //         if (!json.error) {
    //           console.log('oh no');
    //         }
    //       });
    //   }
    // });
  };

  return (
    <Page title="Select a Tier">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Select a Tier
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              {tiers.map((tier) => (
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    handleTierSelect(tier);
                  }}
                  onKeyUp={(e) => handleKeyUp(e, tier)}
                >
                  <RewardCard
                    tier={tier}
                    selected={selected === tier.title}
                    handleSelect={handleSelect}
                  />
                </div>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Invest;
