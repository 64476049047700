import { useContext, useState } from 'react';
import { Typography, Button, Container } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { ref, get, getDatabase, query, orderByChild, equalTo } from 'firebase/database';
import Page from './Page';
import { UserContext } from '../context/UserContext';

const PayWithCredit = () => {
  const auth = getAuth();
  const db = getDatabase();
  const context = useContext(UserContext);
  const [paid, setPaid] = useState(false);

  const handlePayment = () => {
    setPaid(true);
    const walletRef = query(
      ref(db, 'wallets'),
      orderByChild('id'),
      equalTo(auth.currentUser !== null ? auth.currentUser.uid : '')
    );
    get(walletRef).then((snapshot) => {
      console.log(snapshot.exists());
      if (snapshot.exists()) {
        const data = snapshot.val();
        const key = Object.keys(data)[0];
        console.log(data);
        console.log(data[key].address);
        /* eslint-disable */
        let formData = new FormData();
        formData.append('from', '');
        formData.append('to', data[key].address);
        formData.append('amount', context.tier.cost);
        /* eslint-enable */

        fetch(`${process.env.REACT_APP_API_URL}/api/sendtoaddress`, {
          method: 'POST',
          body: formData
        })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);
            if (!(json.error === null)) {
              console.log('oh no');
            }
          });
      }
    });
  };
  return (
    <Page title="Pay With Credit">
      <Container>
        <Typography>
          This page is a work in process. To simulate paying click the button below
        </Typography>
        <Button onClick={handlePayment} disabled={paid}>
          Pay
        </Button>
        {paid && (
          <Typography>
            Thank you for investing, apexgenesiscoin will be transfered to your account within 30
            minutes.
          </Typography>
        )}
      </Container>
    </Page>
  );
};

export default PayWithCredit;
