import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';

// ----------------------------------------------------------------------

const ProjectImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

BrowseProjectCard.propTypes = {
  product: PropTypes.object
};

export default function BrowseProjectCard({ product, projectId }) {
  const { name, cover, amount } = product;
  const [raised, setRaised] = useState('0');
  useEffect(() => {
    /* eslint-disable */
    let formData = new FormData();
    formData.append('wallet', projectId);
    /* eslint-enable */

    fetch(`${process.env.REACT_APP_API_URL}/api/getbalance`, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setRaised(json.result);
        if (!json.error) {
          console.log('oh no');
        }
      });
  }, []);

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <ProjectImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={1} sx={{ p: 3 }}>
        <Link
          to={`/dashboard/view-project/${projectId}`}
          color="inherit"
          underline="hover"
          component={RouterLink}
        >
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>
        <Slider
          min={0}
          max={parseInt(amount, 10)}
          value={parseInt(raised, 10)}
          components={{ Thumb: 'div' }}
          style={{ height: 8 }}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" />
      </Stack>
    </Card>
  );
}
