import { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
// import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import MyProjects from './components/MyProjects';
import ProjectCreation from './components/ProjectCreation';
import EditProject from './components/EditProject';
import BrowseProjects from './components/BrowseProjects';
import ViewProjects from './components/ViewProjects';
import Invest from './components/Invest';
import GetCoins from './components/GetCoins';
import Profile from './components/Profile';
import ForgotPassword from './components/ForgotPassword';
import PayForProject from './components/PayForProject';
import PayWithCredit from './components/PayWithCredit';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/browse" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'projects', element: <MyProjects /> },
        { path: 'create-project', element: <ProjectCreation /> },
        { path: 'edit-project/:project', element: <EditProject /> },
        { path: 'browse', element: <BrowseProjects /> },
        { path: 'view-project/:project', element: <ViewProjects /> },
        { path: 'invest/:project', element: <Invest /> },
        // { path: 'get-more-coins', element: <GetCoins /> },
        { path: 'profile', element: <Profile /> },
        { path: 'pay', element: <PayForProject /> },
        { path: 'paywithcredit', element: <PayWithCredit /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'forgotpassword', element: <ForgotPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
