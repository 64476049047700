import { useContext, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Grid
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/Paid';
import { useNavigate } from 'react-router-dom';
import Page from './Page';
import RewardCard from './RewardCard';
import { UserContext } from '../context/UserContext';

const PayForProject = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();

  const handlePayWithCredit = () => {
    navigate('/dashboard/paywithcredit', { replace: true });
  };

  return (
    <Page title="Select a Tier">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Pay for your project
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List>
              <ListItemButton divider sx={{ padding: 5 }} onClick={handlePayWithCredit}>
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText>Pay with credit card</ListItemText>
              </ListItemButton>
              <ListItemButton divider sx={{ padding: 5 }}>
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText>Pay with Crypto</ListItemText>
              </ListItemButton>
            </List>
          </Grid>
          <Grid item xs={6}>
            <RewardCard tier={context.tier} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PayForProject;
