import React, { useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Web3 from 'web3';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { UserContext } from './context/UserContext';

// ----------------------------------------------------------------------

export default function App() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [tier, setTier] = useState({});
  const web3 = new Web3(Web3.givenProvider);

  useEffect(() => {
    if (window.etherium) {
      console.log('hey');
    }
    console.log(window.etherium);
    console.log(1);
    web3.eth.getAccounts(console.log);
    console.log(2);
  }, []);

  // make sure the status is always up to date
  onAuthStateChanged(auth, (data) => {
    setUser(data);
    console.log(data);
  });

  return (
    <UserContext.Provider value={{ user, auth, tier, setTier }}>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ThemeConfig>
    </UserContext.Provider>
  );
}
