// scroll bar
import 'simplebar/src/simplebar.css';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAg_Mqj_enbPCDG8ZGHR6rFQJTjpI7cyjM',
  authDomain: 'cryptostarter-bcf5a.firebaseapp.com',
  projectId: 'cryptostarter-bcf5a',
  storageBucket: 'cryptostarter-bcf5a.appspot.com',
  messagingSenderId: '202725919023',
  appId: '1:202725919023:web:e56ec9946bc1765a7df9da',
  measurementId: 'G-CVK0PRCWFX'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
