import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { ref, get, getDatabase } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import {
  Container,
  Typography,
  Grid,
  Slider,
  Stack,
  Card,
  CardContent,
  Button
} from '@mui/material';
import Page from './Page';
import RewardCard from './RewardCard';

const ViewProjects = () => {
  const { project } = useParams();
  const db = getDatabase();
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [raiseAmount, setRaiseAmount] = useState('');
  const [tiers, setTiers] = useState([]);
  const [amountRaised, setAmountRaised] = useState('0');
  const navigate = useNavigate();
  const storage = getStorage();
  const auth = getAuth();

  useEffect(() => {
    const projectRef = ref(db, `projects/${project}`);
    get(projectRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setProjectName(data.name);
          setProjectDescription(data.description);
          setRaiseAmount(data.amount);
          setTiers(data.tiers);
          getDownloadURL(storageRef(storage, `images/${project}/${data.thumbnail}`)).then((url) => {
            const img = document.getElementById('image');
            img.setAttribute('src', url);
          });
        } else {
          console.log('nothing');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    /* eslint-disable */
    let formData = new FormData();
    formData.append('wallet', project);
    /* eslint-enable */

    fetch(`${process.env.REACT_APP_API_URL}/api/getbalance`, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAmountRaised(json.result);
        if (!json.error) {
          console.log('oh no');
        }
      });
  }, []);

  const handleInvest = () => {
    console.log(auth);
    // logged out
    if (auth.currentUser === null) {
      navigate('/login', { replace: true });
    } else if (auth.currentUser) {
      // logged in
      navigate(`/dashboard/invest/${project}`);
    }
  };

  return (
    <Page title={projectName}>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {projectName}
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <img alt="cover" id="image" />
            <Slider
              min={0}
              max={parseInt(raiseAmount, 10)}
              value={parseInt(amountRaised, 10)}
              components={{ Thumb: 'div' }}
              style={{ height: 8 }}
            />
            <Typography variant="h5">$&ensp;{amountRaised}</Typography>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              raised out of $ {raiseAmount} goal
            </Typography>
            <Typography variant="h4" color="primary" sx={{ mb: 4 }}>
              Description
            </Typography>
            <Typography variant="body1">{projectDescription}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={3}>
              <Button variant="contained" fullWidth onClick={handleInvest}>
                Invest in this Project
              </Button>
              <Typography variant="h5">Backer Rewards</Typography>
              {tiers.map((tier) => (
                <RewardCard tier={tier} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ViewProjects;
